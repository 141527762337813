import { useAppContext } from "../../context/AppContext";
import ListExperts from "./ListExperts";
import ListLiensUtiles from "./ListLiensUtiles";
import { VscTriangleUp } from "react-icons/vsc";
import { useEffect, useRef, useState } from "react";
import logoGris from "../../assets/oliverLogo_grisFonce.png";
import { ReactComponent as ExpertIcon } from "../../assets/iconeExpert.svg";
import ReservationSection from "../chat_view/chat_message/ReservationSection";
import { IoIosInformationCircle } from "react-icons/io";
import ContactExpertInformationForm from "./ContactExpertInformationForm";
import { GoSearch } from "react-icons/go";
import Modal from "../Modal";
import Cgus from "../auth/Cgus";

const RightPanel = ({ mobileMode = false, rightPanelOpen = true, setRightPanelOpen, sideBarOpen, setSideBarOpen, showPanel,
  setShowPanel }) => {
  const {
    liensUtiles,
    experts,
    messages,
    writing,
    selectedOption,
    clearChat,
    firstShow,
    setFirstShow,


  } = useAppContext();

  const mobilePanelRef = useRef(null);
  const [modalCgusOpen, setModalCgusOpen] = useState(false);

  const [cgus, setCgus] = useState("");

  // useEffect(() => {
  //   setSideBarOpen(false);
  // }, [setSideBarOpen, showPanel]);


  // const [statesidebar, setStateSideBar] = useState(sideBarOpen);
  const [modalLoginOpen, setModalLoginOpen] = useState(false);
  const [modalReservationOpen, setModalReservationOpen] = useState(false);
  const [modalContactExpertInformationOpen, setModalContactExpertInformationOpen] = useState(false);
  const touchStartY = useRef(null);

  const handleTouchMove = (e) => {
    if (touchStartY.current === null) {
      return;
    }
    const touchEndX = e.touches[0].clientY;
    const deltaY = touchStartY.current - touchEndX;
    if (deltaY < -5 && mobilePanelRef.current.scrollTop == 0) {
      setRightPanelOpen(false);
    }

    touchStartY.current = null;
  };

  const handleTouchStart = (e) => {
    touchStartY.current = e.touches[0].clientY;
  };

  useEffect(() => {
    if (firstShow && sideBarOpen) {
      setSideBarOpen(false);
      setFirstShow(false);
    }
  }, [firstShow, sideBarOpen, setSideBarOpen, setFirstShow]);


  if (mobileMode) {

    return (
      !rightPanelOpen ? (
        null
      )
        :
        (
          <div
            className={`transition-transform duration-300 bg-[#F6F9FD] ease-in-out ${mobileMode ? (rightPanelOpen ? "translate-x-0 overflow-y-auto" : "translate-y-full") : (rightPanelOpen ? "translate-x-0" : "-translate-x-full")} absolute h-full md:w-[460px] w-full z-50`}
            onTouchStart={(e) => {
              if (mobileMode && rightPanelOpen) handleTouchStart(e);
            }}
            onTouchMove={(e) => {
              if (mobileMode && rightPanelOpen) handleTouchMove(e);
            }}
          >
            <div className={`${rightPanelOpen ? "h-full  overflow-y-auto" : "hidden"}`} ref={mobilePanelRef}>
              {liensUtiles.length > 0 || experts.length > 0 ? (
                <div className="flex py-3 bg-[#06094F] " onClick={() => setRightPanelOpen(!rightPanelOpen)}>
                  <GoSearch color="#fff" size={30} />
                  <h1 className="text-white font-bold p-1"> En savoir plus</h1>
                </div>
              ) : (messages[messages.length - 1]?.id === -4 ? (

                <div className="flex justify-stretch w-full p-3 bg-[#06094F] border-b-2 border-[#06094F]"
                  onClick={() => setRightPanelOpen(!rightPanelOpen)}
                >
                  <svg width="35" height="35" viewBox="0 0 40 48" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.8354 1.73444C11.2183 1.183 11.9943 1.03399 12.5688 1.40162C13.1432 1.76924 13.2984 2.51428 12.9155 3.06572L9.21104 8.40006H27.0398L23.3354 3.06572C22.9524 2.51428 23.1076 1.76925 23.682 1.40162C24.2565 1.034 25.0325 1.183 25.4155 1.73444L30.0446 8.40042C33.1147 8.4351 35.447 11.0879 34.9284 14.0051L34.1444 18.4156C33.3872 18.0749 32.584 17.8128 31.7461 17.6403L32.464 13.6013C32.7234 12.1423 31.5474 10.8001 29.9838 10.8001H6.26704C4.70341 10.8001 3.52744 12.1423 3.78679 13.6013L6.13316 26.8013C6.33766 27.952 7.3826 28.8001 8.61341 28.8001H18.1425C18.1897 29.6284 18.3342 30.4318 18.5651 31.2001H8.61341C6.17165 31.2001 4.07953 29.5158 3.66878 27.2051L1.32241 14.0051C0.803876 11.0879 3.13618 8.4351 6.20619 8.40042L10.8354 1.73444Z" fill="#fff" />
                    <path d="M33.444 26.5868C33.8965 26.0863 33.8408 25.3283 33.3195 24.8939C32.7981 24.4595 32.0086 24.513 31.5561 25.0135L28.2023 28.7225L27.2287 27.5461C26.7993 27.0272 26.013 26.9408 25.4725 27.353C24.9319 27.7654 24.8419 28.5202 25.2713 29.0391L27.181 31.3466C27.4123 31.626 27.761 31.7921 28.1326 31.7998C28.5042 31.8076 28.8601 31.6562 29.1037 31.3868L33.444 26.5868Z" fill="#fff" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20 28.2C20 23.2294 24.1973 19.2 29.375 19.2C34.5527 19.2 38.75 23.2294 38.75 28.2C38.75 33.1705 34.5527 37.2 29.375 37.2C24.1973 37.2 20 33.1705 20 28.2ZM29.375 21.6C25.5781 21.6 22.5 24.5549 22.5 28.2C22.5 31.845 25.5781 34.8 29.375 34.8C33.1719 34.8 36.25 31.845 36.25 28.2C36.25 24.5549 33.1719 21.6 29.375 21.6Z" fill="#fff" />
                  </svg>
                  <h1 className="text-white font-bold p-1 "> Réserver ma formalité</h1>

                </div>
              ) : "")
              }

              {(
                <div className={`w-full flex justify-center ${rightPanelOpen ? "" : "top-[-14px]"}`} >
                  <div className={"w-20 flex"} onClick={() => setRightPanelOpen(!rightPanelOpen)}>
                    <VscTriangleUp
                      className={`w-20 border bg-[#06094F] border-[#06094F] flex items-center justify-center cursor-pointer text-white ${rightPanelOpen ? "rotate-180" : ""}`}
                    />
                  </div>
                </div>
              )}
              {
                messages[messages.length - 1]?.id === -4 ?
                  (
                    <div className={`${writing && "hidden"} relative ${rightPanelOpen ? "mt-4" : ""}  overflow-y-auto`}>
                      {/* <div className="flex mt-4">
                        <svg width="40" height="48" viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.8354 1.73444C11.2183 1.183 11.9943 1.03399 12.5688 1.40162C13.1432 1.76924 13.2984 2.51428 12.9155 3.06572L9.21104 8.40006H27.0398L23.3354 3.06572C22.9524 2.51428 23.1076 1.76925 23.682 1.40162C24.2565 1.034 25.0325 1.183 25.4155 1.73444L30.0446 8.40042C33.1147 8.4351 35.447 11.0879 34.9284 14.0051L34.1444 18.4156C33.3872 18.0749 32.584 17.8128 31.7461 17.6403L32.464 13.6013C32.7234 12.1423 31.5474 10.8001 29.9838 10.8001H6.26704C4.70341 10.8001 3.52744 12.1423 3.78679 13.6013L6.13316 26.8013C6.33766 27.952 7.3826 28.8001 8.61341 28.8001H18.1425C18.1897 29.6284 18.3342 30.4318 18.5651 31.2001H8.61341C6.17165 31.2001 4.07953 29.5158 3.66878 27.2051L1.32241 14.0051C0.803876 11.0879 3.13618 8.4351 6.20619 8.40042L10.8354 1.73444Z" fill="#06094F" />
                          <path d="M33.444 26.5868C33.8965 26.0863 33.8408 25.3283 33.3195 24.8939C32.7981 24.4595 32.0086 24.513 31.5561 25.0135L28.2023 28.7225L27.2287 27.5461C26.7993 27.0272 26.013 26.9408 25.4725 27.353C24.9319 27.7654 24.8419 28.5202 25.2713 29.0391L27.181 31.3466C27.4123 31.626 27.761 31.7921 28.1326 31.7998C28.5042 31.8076 28.8601 31.6562 29.1037 31.3868L33.444 26.5868Z" fill="#06094F" />
                          <path fill-rule="evenodd" d="M20 28.2C20 23.2294 24.1973 19.2 29.375 19.2C34.5527 19.2 38.75 23.2294 38.75 28.2C38.75 33.1705 34.5527 37.2 29.375 37.2C24.1973 37.2 20 33.1705 20 28.2ZM29.375 21.6C25.5781 21.6 22.5 24.5549 22.5 28.2C22.5 31.845 25.5781 34.8 29.375 34.8C33.1719 34.8 36.25 31.845 36.25 28.2C36.25 24.5549 33.1719 21.6 29.375 21.6Z" fill="#06094F" />
                        </svg>
                        <h1 className="text-first-oliver font-bold p-1"> Réserver ma formalité</h1>
                      </div> */}
                      <div className="leading-5 bg-[#8EBAEA] rounded-2xl mr-2">
                        <ReservationSection
                          modalLoginOpen={modalLoginOpen}
                          setModalLoginOpen={setModalLoginOpen}
                          modalReservationOpen={modalReservationOpen}
                          setModalReservationOpen={setModalReservationOpen}
                          writing={writing}
                        />
                      </div>
                    </div>

                  ) : ""
              }
              {(liensUtiles.length > 0 || experts.length > 0) &&
                (
                  <>
                    <div className="h-full overflow-y-hidden border-b-2 mt-4 border-[#06094F]">
                      <ListExperts experts={experts} />
                      <ListLiensUtiles liensUtiles={liensUtiles} />
                    </div>
                  </>
                )
              }

            </div>
          </div >
        )

    );
  }
  else {
    return (
      <div
        className={`relative hidden md:h-full
      ${mobileMode ? `${(liensUtiles.length > 0 || experts.length > 0) ? "block" : "hidden"} 
      ${rightPanelOpen ? "h-full" : "h-[10%]"} border-t-4 border-neutral-600 duration-300 w-full relative md:hidden` : `hidden md:block ${messages[messages.length - 1]?.id === -4 ? 'min-w-[550px] w-[550px]' : 'min-w-[400px] w-[400px]'} `}`}
      >
        {/* relative hidden md:h-full
      hidden md:block min-w-[400px] w-[400px]  */}
        <div className="h-[100%] flex flex-col">
          {
            messages[messages.length - 1]?.id === -4 ?
              (<div className={`${writing && "hidden"} relative overflow-y-auto`}>
                <div className="flex justify-between ">
                  <div className="flex mt-4">
                    <svg width="35" height="35" viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.8354 1.73444C11.2183 1.183 11.9943 1.03399 12.5688 1.40162C13.1432 1.76924 13.2984 2.51428 12.9155 3.06572L9.21104 8.40006H27.0398L23.3354 3.06572C22.9524 2.51428 23.1076 1.76925 23.682 1.40162C24.2565 1.034 25.0325 1.183 25.4155 1.73444L30.0446 8.40042C33.1147 8.4351 35.447 11.0879 34.9284 14.0051L34.1444 18.4156C33.3872 18.0749 32.584 17.8128 31.7461 17.6403L32.464 13.6013C32.7234 12.1423 31.5474 10.8001 29.9838 10.8001H6.26704C4.70341 10.8001 3.52744 12.1423 3.78679 13.6013L6.13316 26.8013C6.33766 27.952 7.3826 28.8001 8.61341 28.8001H18.1425C18.1897 29.6284 18.3342 30.4318 18.5651 31.2001H8.61341C6.17165 31.2001 4.07953 29.5158 3.66878 27.2051L1.32241 14.0051C0.803876 11.0879 3.13618 8.4351 6.20619 8.40042L10.8354 1.73444Z" fill="#06094F" />
                      <path d="M33.444 26.5868C33.8965 26.0863 33.8408 25.3283 33.3195 24.8939C32.7981 24.4595 32.0086 24.513 31.5561 25.0135L28.2023 28.7225L27.2287 27.5461C26.7993 27.0272 26.013 26.9408 25.4725 27.353C24.9319 27.7654 24.8419 28.5202 25.2713 29.0391L27.181 31.3466C27.4123 31.626 27.761 31.7921 28.1326 31.7998C28.5042 31.8076 28.8601 31.6562 29.1037 31.3868L33.444 26.5868Z" fill="#06094F" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M20 28.2C20 23.2294 24.1973 19.2 29.375 19.2C34.5527 19.2 38.75 23.2294 38.75 28.2C38.75 33.1705 34.5527 37.2 29.375 37.2C24.1973 37.2 20 33.1705 20 28.2ZM29.375 21.6C25.5781 21.6 22.5 24.5549 22.5 28.2C22.5 31.845 25.5781 34.8 29.375 34.8C33.1719 34.8 36.25 31.845 36.25 28.2C36.25 24.5549 33.1719 21.6 29.375 21.6Z" fill="#06094F" />
                    </svg>
                    <h1 className="text-first-oliver font-bold p-1 "> Réserver ma formalité</h1>
                  </div>
                  <div className="flex mt-3 mr-4">
                    <label
                      onClick={() => { setShowPanel(false) }}
                      className="btn btn-sm btn-circle items-center text-[#06094F] bg-[#F1F1FF]"
                    >
                      ✕
                    </label>
                  </div>
                </div>

                <div className="leading-5 bg-[#8EBAEA] rounded-2xl mr-2">
                  <ReservationSection
                    modalLoginOpen={modalLoginOpen}
                    setModalLoginOpen={setModalLoginOpen}
                    modalReservationOpen={modalReservationOpen}
                    setModalReservationOpen={setModalReservationOpen}
                    writing={writing}
                  />
                </div>
              </div>
              ) : (
                <>
                  <div className="flex justify-between ">

                    <div className="flex mt-4">
                      <GoSearch color="#06094F" size={30} />
                      <h1 className="text-first-oliver font-bold p-1"> En savoir plus</h1>
                    </div>

                    <div className="flex mt-3 mr-4">
                      <label
                        onClick={() => { setShowPanel(false) }}
                        className="btn btn-sm btn-circle items-center text-[#06094F] bg-[#F1F1FF]"
                      >
                        ✕
                      </label>
                    </div>
                  </div>

                  <div className="relative overflow-x-hidden">
                    <ListExperts experts={experts} />
                    <ListLiensUtiles liensUtiles={liensUtiles} />
                  </div>
                </>
              )
          }
        </div>
      </div >
    );
  }

};

export default RightPanel;