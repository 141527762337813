import React from "react";
import { useState, useEffect } from "react";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { GoDotFill, GoDot } from "react-icons/go";
import { FaChevronDown, FaChevronUp, FaEnvelope } from "react-icons/fa";
import logoInfogreffe from "../../../assets/logoOliver_vanillaDark3.jpg";
import logoMesFormalites from "../../../assets/MesFormalites.Legal4.png";
import moment from "moment";
import "moment/locale/fr";
import Document from "./Document";
import { useAppContext } from "../../../context/AppContext.js";
import ReservationSection from "./ReservationSection"

import light from "../../../assets/light.png";
import InfosInput from "../../anpi/InfosInput.jsx";
import { updateMessage, update_last_system_message_tokens, createMessage } from '../../../utils/conversation';

import { db } from "../../../firebase.config";
import { doc, getDoc, getDocs, collection, query, where } from "firebase/firestore";

import Modal from "../../Modal";
import Evaluation from "../../Evaluation.jsx";
import { FaRegStar, FaStar } from "react-icons/fa";
import Contact from "../../Contact.jsx";
import { fetchData } from "../../../utils/ask_api.js";


/**
 * Composant AssistantMessage affiche les messages générés par le système/assistant.
 * Il peut traiter différents types de contenu, y compris les formalités d'entreprise.
 *
 * @param {Object} props Les props du composant
 * @param {number} props.message_id Identifiant unique du message
 * @param {number} props.index Index du message dans la liste des messages
 * @param {Array} props.documents Documents liés au message, si applicable
 * @param {string} props.content Contenu du message
 * @param {Date} props.createdAt Date de création du message
 * @param {Object} props.metadatas Métadonnées supplémentaires liées au message
 * @param {boolean} props.writing Indique si un message est en cours de rédaction
 * @param {Function} props.setVersionsOpen Handler pour ouvrir les versions du chat
 */
const AssistantMessage = ({
  message_id,
  index,
  documents,
  content,
  createdAt,
  metadatas,
  writing,

  setVersionsOpen,
  handleOptionClick
}) => {
  const {
    messages,
    selectedOption,
    streamError,
    version,
    nameModel,
    model,
    discriptionFormalite,

    setDiscriptionFormalite,
    subdomain,
    subDomains,
    setSelectedOption,
    setWriting,
    setMessages,
    retryMessage,
  } = useAppContext();

  const [modalLoginOpen, setModalLoginOpen] = useState(false);
  const [modalReservationOpen, setModalReservationOpen] = useState(false);
  const [modalEvaluationOpen, setModalEvaluationOpen] = useState(false);
  const [modalContactOpen, setModalContactOpen] = useState(false);
  const [showArticles, setShowArticles] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const [starRating, setStarRating] = useState(0);
  const [oldStarRating, setOldStarRating] = useState(0);
  const [hoveredStar, setHoveredStar] = useState(null);

  // Crée un contexte pour gérer les niveaux de liste dans le markdown.
  const ListLevelContext = React.createContext(0);

  // Composant personnalisé pour rendre les éléments de liste en tenant compte de leur niveau d'indentation.
  const LiRenderer = ({ children }) => {
    const level = React.useContext(ListLevelContext);

    return (
      <li className="flex hover:underline-offset-4">
        <div className="mt-[0.25em] mr-[2px] flex">
          {level > 0 ? (
            <GoDotFill className="w-3" />
          ) : (
            <GoDot className="w-3" />
          )}
        </div>
        <div>{children}</div>
      </li>
    );
  };

  // Composant personnalisé pour rendre les listes non ordonnées avec un style spécifique en fonction de leur niveau.
  const UlRenderer = ({ children }) => {
    const level = React.useContext(ListLevelContext);
    const nextLevel = level + 1;
    const className = level === 0 ? "space-y-[1px] list-none hover:list-disc" : "nested-ul-style";
    return (
      <ListLevelContext.Provider value={nextLevel}>
        <ul className={className}>{children}</ul>
      </ListLevelContext.Provider>
    );
  };





  async function get_text_by_category_and_titleFormalite(category, titreFormalite) {
    try {
      const docRef = doc(db, `annuaire_formalites_${category}`, titreFormalite);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data().description;
      } else {
        return "Texte non trouvé pour la combinaison spécifiée.";
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
      return "Erreur lors de la récupération des données.";
    }
  }

  function formatTextToMarkdown(text) {
    // Remplacer les espaces multiples par un seul espace
    let formattedText = text.replace(/\s+/g, ' ');

    // Insérer un saut de ligne Markdown avant chaque élément de liste
    formattedText = formattedText.replace(/- /g, '\n\n- ');

    // Insérer un saut de ligne Markdown avant chaque élément de liste numérotée
    formattedText = formattedText.replace(/(\d+)\. /g, '\n\n$1. ');

    // Insérer un saut de ligne Markdown avant chaque titre
    formattedText = formattedText.replace(/Liste des pièces requises/g, '\n\n## Liste des pièces requises');
    formattedText = formattedText.replace(/Pièces à télécharger :/g, '\n\n## Pièces à télécharger :');

    // Ajouter des sauts de ligne supplémentaires pour séparer les paragraphes
    formattedText = formattedText.replace(/(\.)([A-Z])/g, '$1\n\n$2');

    return formattedText;
  }

  function isValidCategory(children) {
    const normalized = children.trim().replace(/\s+/g, ' ').toLowerCase();

    // Liste des mots-clés valides pour vérifier la présence de "SASU", "SAS", "SARL", ou "EURL"
    const validKeywords = ['sasu', 'sas', 'sarl', 'eurl'];

    // Créer un tableau pour stocker les mots-clés trouvés
    let foundKeywords = [];

    // Vérifie si l'un des mots-clés valides est présent dans la chaîne normalisée et les ajoute au tableau
    validKeywords.forEach(keyword => {
      if (normalized.includes(keyword)) {
        foundKeywords.push(keyword.toUpperCase()); // Ajoute le mot-clé en majuscules
      }
    });

    // Retourne un booléen pour indiquer si au moins un mot-clé a été trouvé et la liste des mots-clés trouvés
    return {
      isValid: foundKeywords.length > 0,
      categories: foundKeywords
    };
  }


  const handleClick = (href, children) => {
    setWriting(true);
    const newMsg = createMessage(children, "user", undefined, {}, 0);
    updateMessage(newMsg, setMessages);
    get_text_by_category_and_titleFormalite(selectedOption.action, children)
      .then(markdownContent => {
        // const categoryResult = isValidCategory(children);
        // if (categoryResult.isValid && selectedOption.action === "creation") {
        //   setDiscriptionFormalite(markdownContent)
        //   markdownContent = "S'agit-il d'une société ne comptant qu'un seul associé unique (personne physique ou personne morale) ?"
        //   markdownContent = formatTextToMarkdown(markdownContent);
        //   console.log("After\n", markdownContent, "\ndiscriptionFormalite: '", discriptionFormalite)
        //   const words = markdownContent.split(" ");
        //   // Déterminer le type de société basé sur les catégories trouvées
        //   let options;
        //   if (categoryResult.categories.includes('SASU') || categoryResult.categories.includes('SAS')) {
        //     options = { oui: "Oui (SASU)", non: "Non (SAS)" };
        //   } else if (categoryResult.categories.includes('EURL') || categoryResult.categories.includes('SARL')) {
        //     options = { oui: "Oui (EURL)", non: "Non (SARL)" };
        //   }
        //   const newMsgSystem = createMessage("", "system", undefined, options, -5);
        //   updateMessage(newMsgSystem, setMessages);

        //   (async () => {
        //     for (const word of words) {
        //       const randomTimeout = 5;
        //       await new Promise(resolve => setTimeout(resolve, randomTimeout));
        //       if (!writing) {
        //         setWriting(true);
        //       }
        //       // Met à jour le message système avec le mot actuel
        //       update_last_system_message_tokens(`${word} `, setMessages);
        //     }
        //     setWriting(false);
        //   })();
        // } else {
        markdownContent = formatTextToMarkdown(markdownContent);
        console.log("After\n", markdownContent)
        const words = markdownContent.split(" ");
        const newMsgSystem = createMessage("", "system", undefined, {}, -4);
        updateMessage(newMsgSystem, setMessages);

        (async () => {
          for (const word of words) {
            const randomTimeout = 5;
            await new Promise(resolve => setTimeout(resolve, randomTimeout));
            if (!writing) {
              setWriting(true);
            }
            // Met à jour le message système avec le mot actuel
            update_last_system_message_tokens(`${word} `, setMessages);
          }
          setWriting(false);
        })();
        // }

      })
      .catch(error => {
        console.error("Erreur lors de la récupération du contenu :", error);
        // Gérer l'erreur, par exemple, en affichant un message d'erreur à l'utilisateur
      });
  };

  function respenseUserForQuestionYesOrNo(answer) {
    setWriting(true);
    const newMsg = createMessage(answer, "user", undefined, {}, 1);
    updateMessage(newMsg, setMessages);
    console.log("discriptionFormalite: ", discriptionFormalite)
    formatTextToMarkdown(discriptionFormalite);
    console.log("After\n", discriptionFormalite)
    const words = discriptionFormalite.split(" ");
    const newMsgSystem = createMessage(discriptionFormalite, "system", undefined, {}, -4);
    updateMessage(newMsgSystem, setMessages);

    (async () => {
      for (const word of words) {
        const randomTimeout = 5;
        await new Promise(resolve => setTimeout(resolve, randomTimeout));
        if (!writing) {
          setWriting(true);
        }
        // Met à jour le message système avec le mot actuel
        update_last_system_message_tokens(`${word} `, setMessages);
      }
      setWriting(false);
    })();
  }

  // Définit des renderers personnalisés pour ReactMarkdown afin de personnaliser l'affichage du markdown.
  const md_renderers = {
    // Define a custom renderer for links
    table: ({ children }) => (
      <div className="overflow-x-auto overflow-y-hidden w-full relative">
        <table className="table">{children}</table>
      </div>
    ),
    a: ({ node, ...props }) => {
      if (selectedOption && ['creation', 'modification', 'cessation', 'secretariat'].includes(selectedOption.action)) {
        return <a className="underline font-semibold break-all" {...props} onClick={(e) => {
          e.preventDefault();
          setSelectedOption({ ...selectedOption, titleFormalite: props.children });
          handleClick(props.href, props.children)
        }} />
      }
      return <a className="underline font-semibold break-all" {...props} target="_blank" />
    }
    ,
    ol: ({ children }) => <ol className="space-y-1">{children}</ol>,
    ul: UlRenderer,
    li: LiRenderer,
    thead: ({ children }) => <thead className="text-inherit	">{children}</thead>,
    tr: ({ children }) => <tr className=" border-gray-400">{children}</tr>,
    // link: (props) => <CustomLink {...props} onClick={handleLinkClick} />
  };

  // Permet d'afficher ou de cacher les documents/articles liés au message.
  const toggleArticles = () => {
    setShowArticles((prevShowArticles) => !prevShowArticles);
  };

  // // Réinitialise l'affichage des filtres si aucune option n'est sélectionnée.
  useEffect(() => {
    if (!selectedOption) {
      setSelectedOption({ ...selectedOption, displayOptionsFormalite: true });
    }
  }, [selectedOption, setSelectedOption]);

  useEffect(() => {
    if (modalEvaluationOpen === false && starRating <= 2) {
      console.log("useEffect oldStarRating: ", oldStarRating);

      setStarRating(oldStarRating); setHoveredStar(null);
    };

  }, [modalEvaluationOpen, oldStarRating])


  const normalizeString = (str) => str.trim().replace(/^ +/gm, '');

  const handleStarClick = async (rating) => {
    setStarRating(rating);
    if (rating <= 2) {
      setModalEvaluationOpen(true);
      return;
    }

    const evaluationData = {
      question: messages[messages.length - 2].content,
      response: messages[messages.length - 1].content,
      date: new Date(),
      name_model: nameModel,
      model: model,
      version_model: version,
      nb_etoile: rating,
    };

    try {
      console.log("Données d'évaluation à envoyer:", evaluationData);
      const data = await fetchData("evaluation", evaluationData, "POST");
      console.log(data)
      setOldStarRating(rating);
      setModalOpen(true);
    } catch (err) {
      alert("Erreur lors de l'envoi de l'évaluation");
    }

  };

  const onEvaluationSubmit = async (message) => {
    try {
      const evaluationData = {
        question: messages[messages.length - 2].content,
        response: messages[messages.length - 1].content,
        date: new Date(),
        name_model: nameModel,
        model: model,
        version_model: version,
        nb_etoile: starRating,
        msg_evaluation: message,
      };
      console.log("Données d'évaluation à envoyer:", evaluationData);
      const data = await fetchData("evaluation", evaluationData, "POST");
      console.log(data)
      setModalEvaluationOpen(false);
      setModalOpen(true);
      setOldStarRating(starRating);
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'évaluation", error);
      alert("Erreur lors de l'envoi de l'évaluation");
    }
  };


  return (
    <div className="w-[98%]">
      <p className="text-left mt-2 font-bold text-[#06094F]"> {subDomains.includes(subdomain) ? "Mes Formalités Legal" : "Oliver"}  </p>
      <>
        {/* Gestion de l'affichage d'erreur de connexion avec l'API OpenAI */}
        {index === messages.length - 1 && streamError ? (
          <>
            <div>
              <p className={`text-xl text-red-600 text-left`}>
                Désolé, mais l'api ne répond pas. Veuillez réinitialiser votre question ou réessayer plus tard.
              </p>
              <button
                className="font-medium border-2 border-red-600 text-red-600  rounded px-2 py-1
               hover:bg-red-600 hover:text-white"
                onClick={() => retryMessage()}
              >
                Réinitialiser
              </button>
            </div>
            <div className={`${"text-left"} message__createdAt pr-4 ${!writing && "-ml-8"}`}>
              {moment(createdAt).calendar()}
            </div>
          </>
        ) : (
          <>
            {/* Affichage du contenu du message avec ReactMarkdown pour interpréter le markdown */}
            {content.length > 0 ? (
              <>
                <div className="w-full">
                  <div className={`message__markdown text-left text-base	w-full`}>
                    <ReactMarkdown
                      className={"w-full	space-y-2"}
                      remarkPlugins={[remarkGfm]}
                      components={md_renderers}
                    >
                      {normalizeString(content)}
                    </ReactMarkdown>
                    {/* Affichage conditionnel de l'evation et note ou d'un conseil sous le message */}
                    <div className="pr-4 mt-2">
                      <div className={`${(index === 0) | (writing && index === messages.length - 1) | (message_id < 0) && "hidden"} flex items-center`}>
                        <label className="block text-sm font-bold text-[#06094F] dark:text-white my-1 mr-4">
                          Cette réponse vous a-t-elle été utile ?
                        </label>
                        <div className="flex">
                          {[...Array(4)].map((_, i) => (
                            <span
                              key={i}
                              onClick={() => handleStarClick(i + 1)}
                              onMouseEnter={() => setHoveredStar(i + 1)}
                              onMouseLeave={() => setHoveredStar(null)}
                              className="cursor-pointer hover:scale-125 transition-transform duration-200"
                            >
                              {starRating > i || hoveredStar > i ? (
                                <FaStar className="text-orange-logo-color mr-1 w-5 h-5" />
                              ) : (
                                <FaRegStar className="text-orange-logo-color mr-1 w-5 h-5" />
                              )}
                            </span>
                          ))}
                        </div>
                      </div>

                      <div
                        className={`${(index === 0) | (writing && index === messages.length - 1) | (message_id < 0) && "hidden"} 
                         bg-[#F4F4F4] p-2 opacity-70 rounded-lg flex items-center space-x-2 mt-1`}
                      >
                        <img src={light} className="w-3 m-[1px]" />

                        {version === 0 ? (
                          <p className="text-xs leading-[1.2em]">
                            L'assistant juridique ne se substitue pas aux professionnels du droit,
                            seuls habilités à faire du conseil juridique. N’hésitez
                            pas à vérifier les informations importantes et à nous faire part de vos <span
                              className="font-bold cursor-pointer"
                              onClick={() => setModalContactOpen(!modalContactOpen)}
                            >retours par écrit.</span>
                          </p>
                        ) : (
                          <p className="text-xs leading-[1.2em]">
                            L'assistant juridique ne se substitue pas aux professionnels du droit,
                            seuls habilités à faire du conseil juridique. N’hésitez
                            pas à vérifier les informations importantes et à nous faire part de vos <span
                              className="font-bold cursor-pointer"
                              onClick={() => setModalContactOpen(!modalContactOpen)}
                            >retours par écrit.</span>
                          </p>
                        )}

                      </div>
                      {/* Affichage conditionnel du bouton de réservations */}
                      {message_id === -5 && (
                        <>
                          <div className="flex sm:w-full w-[95%] justify-center sm:flex-row flex-col mt-4">
                            <button
                              onClick={() => respenseUserForQuestionYesOrNo(metadatas.oui)}
                              className="filter_button_suivre_formalite m-1"
                            >{metadatas.oui}</button>
                            <button
                              type="button"
                              onClick={() => respenseUserForQuestionYesOrNo(metadatas.non)}
                              className="filter_button_suivre_formalite m-1"
                            >
                              {metadatas.non}
                            </button>
                          </div>
                        </>
                      )}
                      {/* Affichage des documents/articles liés au message */}
                      {documents.length > 0 && (
                        <>
                          <div className="opacity-70">
                            {showArticles &&
                              documents.map((document, index) => (
                                <Document key={index} document={document} />
                              ))}
                          </div>
                          <button
                            className={`text-[#06094F] opacity-70 bg-[#F4F4F4] text-xs p-1 rounded-lg flex items-center space-x-2 mt-1`}
                            onClick={toggleArticles}
                          >
                            {showArticles ? (
                              <>
                                <span>Cacher les sources</span>
                                <FaChevronUp />
                              </>
                            ) : (
                              <>
                                <span>Afficher les sources utilisées</span>
                                <FaChevronDown />
                              </>
                            )}
                          </button>
                        </>
                      )}
                    </div>
                    <div className={`text-left message__createdAt pr-4 `}>
                      {moment(createdAt).calendar()}
                    </div>
                    <Modal
                      title={"Contact"}
                      modalOpen={modalContactOpen}
                      setModalOpen={setModalContactOpen}
                      modalSize={"auto"}
                      titleIcon={<FaEnvelope className="mr-2 text-[1.3em]" />}
                    >
                      <Contact setOpen={setModalContactOpen} />
                    </Modal>

                    <Modal
                      title={"Evaluation"}
                      modalOpen={modalEvaluationOpen}
                      setModalOpen={setModalEvaluationOpen}
                      modalSize={"auto"}
                      setStarRating={setStarRating}
                      setHoveredStar={setHoveredStar}
                      titleIcon={<FaEnvelope className="mr-2 text-[1.3em]" />}
                    >
                      <Evaluation onEvaluationSubmit={onEvaluationSubmit} />
                    </Modal>

                    <Modal
                      title=""
                      modalOpen={isModalOpen}
                      setModalOpen={setModalOpen}
                      modalSize={"auto"}
                    >
                      <div className="flex flex-col items-center justify-center p-4">
                        <img src={`${subDomains.includes(subdomain) ? logoMesFormalites : logoInfogreffe}`} alt="Logo" className="w-40 mb-6" />
                        <p className="text-center mb-6"> Évaluation enregistrée avec succès. </p>
                        <button
                          onClick={() => setModalOpen(false)}
                          className="signupFormContainerContinuer text-white font-bold"
                        >
                          OK
                        </button>
                      </div>
                    </Modal>

                  </div>
                  {index === 1 && selectedOption && selectedOption.action === 'formalities' && (
                    <div className="flex sm:w-full w-[95%] justify-center sm:flex-row flex-col mt-4">
                      <button
                        className="filter_button_suivre_formalite m-1"
                        onClick={() => handleOptionClick(
                          {
                            'class': 'filter_button_suivre_formalite',
                            'name': "Réaliser une formalité",
                            'action': 'effectuer',
                            'key': index
                          }
                        )}>{"Réaliser une formalité"}</button>
                      <button
                        type="button"
                        className="filter_button_suivre_formalite m-1"
                        onClick={() => window.open("https://www.infogreffe.fr/formalites/rechercher-une-formalite", "_blank", "noopener,noreferrer")}
                      >
                        Suivre une formalité
                      </button>
                    </div>
                  )}
                  {index === 3 && selectedOption && selectedOption.action === 'effectuer' && (
                    <div className="flex w-full flex-wrap justify-center mt-4">
                      <button
                        className="filter_button_suivre_formalite m-1"
                        onClick={() => handleOptionClick(
                          {
                            'class': 'filter_button_suivre_formalite',
                            'name': "Création",
                            'action': 'creation',
                            'key': index
                          }
                        )}>Création</button>
                      <button
                        className="filter_button_suivre_formalite m-1"
                        onClick={() => handleOptionClick(
                          { 'class': 'filter_button_suivre_formalite', 'name': "Modification", 'action': 'modification', 'key': index }
                        )}>Modification</button>
                      <button
                        className="filter_button_suivre_formalite m-1"
                        onClick={() => handleOptionClick(
                          { 'class': 'filter_button_suivre_formalite', 'name': "Cessation", 'action': 'cessation' }
                        )}>Cessation</button>
                      <button
                        className="filter_button_suivre_formalite m-1"
                        onClick={() => handleOptionClick(
                          { 'class': 'filter_button_suivre_formalite', 'name': "Secrétariat juridique", 'action': 'secretariat', 'key': index }
                        )}>Secrétariat juridique</button>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="w-full">
                  <div className={`message__markdown message__thinking text-left text-base w-full`}>
                    <ReactMarkdown
                      className={"w-full 	space-y-2"}
                      remarkPlugins={[remarkGfm]}
                      components={md_renderers}
                    >
                      {normalizeString("je compile...")}
                    </ReactMarkdown>
                  </div>
                </div>
                <div className={`${"text-left"} message__createdAt pr-4`}>
                  {moment(createdAt).calendar()}
                </div>
              </>
            )}


          </>
        )}

      </>
      {/* Affichage conditionnel d'un input pour des informations supplémentaires */}
      {/* Lien vers Infogreffe pour les messages contenant un SIREN */}
      {message_id === -3 && (
        <div>
          <a
            href={`https://www.infogreffe.fr/entreprise/${metadatas.company_siren ? metadatas.company_siren : ""
              }`}
            target="_blank"
            rel="noreferrer"
            className="w-full text-center"
          >
            <p className="text-center  border-[2.5px] border-[#C048FB] rounded-lg py-2 p-1 my-2 font-semibold text-[0.85em] text-[#C048FB] hover:bg-[#C048FB] hover:text-white">
              Aller sur la fiche Infogreffe pour commander un KBIS ou document
              officiel
            </p>
          </a>
        </div>
      )}
      {/* Affichage conditionnel des options formalitées */}
      <div>
        {/* {console.log("AssistantMessage#### displayOptionsFormalite: ", displayOptionsFormalite)}
      {console.log("AssistantMessage#### selectedOption?.displayOptionsFormalite: ", selectedOption?.displayOptionsFormalite)} */}

        {/* {console.log("AssistantMessage#### selectedOption: ", selectedOption)}
        {console.log("AssistantMessage#### displayOptionsFormalite: ", displayOptionsFormalite)}
        {console.log("AssistantMessage#### index: ", index)} */}

        {index === 4 && selectedOption && selectedOption.action === 'suivre' && (
          <InfosInput />
        )}

        {index === 2 && selectedOption && selectedOption.action === 'checkup' && <InfosInput />}


      </div>

    </div>
  );
};

export default AssistantMessage;
