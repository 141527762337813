import React, { useState } from "react";
import {
  signOut,
  createUserWithEmailAndPassword,
  signInWithPopup,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { IoIosEye, IoMdEyeOff } from "react-icons/io";

import { auth, goggleAuthProvider } from "../../firebase.config";
import { useColorsContext } from "../../context/GlobalContext";
import { fetchData } from "../../utils/ask_api";
import { useNavigate } from "react-router-dom";
import ConfirmationPopup from "./ConfirmationPopup";

const LoginFormInfogreffe = ({ closeModal, setCgus, action, setAction }) => {

  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");

  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptCgus, setAcceptCgus] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // Validate email format
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };

  // Update event handlers
  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsEmailValid(validateEmail(emailValue));
  };

  const handleFirstNameChange = (e) => {
    const firstNameValue = e.target.value.trim();
    setFirstName(firstNameValue);
    setIsFirstNameValid(firstNameValue.length > 0);
  };

  const handleLastNameChange = (e) => {
    const lastNameValue = e.target.value.trim();
    setLastName(lastNameValue);
    setIsLastNameValid(lastNameValue.length > 0);
  };

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setFirstName("");
    setLastName("");
    setConfirmPassword("");
    setIsFirstNameValid(true);
    setIsLastNameValid(true);
    setIsEmailValid(true);
    setAction("");
    closeModal();
    setErrorMessage("");
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      let userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log("userCredential.user :", userCredential.user);
      if (!userCredential.user.emailVerified) {
        setErrorMessage("Veuillez d'abord vérifier votre adresse e-mail.");
        await signOut(auth);
        return;
      }
      const redirectPath = sessionStorage.getItem('redirectPath') || '/';
      sessionStorage.removeItem('redirectPath');
      navigate(redirectPath);
      resetForm();
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      setErrorMessage(errorMessage);
    }
  };

  const handleForgotPassword = async () => {
    // Check if email is empty
    if (email.trim() === "") {
      setErrorMessage("Veuillez entrer votre adresse e-mail.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setErrorMessage("Un email contenant un lien vous a été envoyé.");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      setErrorMessage(
        "Une erreur est survenue, veuillez entrer un email associé à un compte."
      );
    }
  };

  const handleSignInWithGoggle = async () => {
    try {
      let userCredential = await signInWithPopup(auth, goggleAuthProvider);
      // once user is signed in navigate them to the home page
      console.log(userCredential);
      if (userCredential._tokenResponse.isNewUser) {
        const d = await fetchData("add_user", null, "POST");
      }
      const redirectPath = sessionStorage.getItem('redirectPath') || '/';
      sessionStorage.removeItem('redirectPath');
      navigate(redirectPath);
      resetForm();
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      setErrorMessage(errorMessage);
    }
  };

  const showConfirmationPopup = () => {
    setIsPopupVisible(true);
  };


  const handleSignup = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password !== confirmPassword) {
      setErrorMessage("Les mots de passes ne correspondent pas.");
      return;
    }

    try {
      let userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log(userCredential);
      await updateProfile(userCredential.user, {
        displayName: `${firstName} ${lastName}`,
      });
      await sendEmailVerification(userCredential.user);
      const d = await fetchData("add_user", null, "POST");
      // await signOut(auth);
      showConfirmationPopup();
      navigate("/waiting-for-confirmation");
      // resetForm();
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      setErrorMessage(errorMessage);
    }
  };

  return (
    <div className="signupFormContainer w-full">
      {action !== "" ? (
        <>
          <button
            className={`signupFormContainerRetour text-first-oliver`}
            onClick={() => setAction("")}
          >
            Retour
          </button>
          <form
            onSubmit={action === "login" ? handleLogin : handleSignup}
            className="signupFormContainerForm loginContainerContent w-full"
          >
            {action === "signup" && (
              <>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="Prénom"
                  className="text-input"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  required
                  style={{ borderColor: isFirstNameValid ? "" : "red" }}
                />
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Nom"
                  className="text-input"
                  value={lastName}
                  onChange={handleLastNameChange}
                  required
                  style={{ borderColor: isLastNameValid ? "" : "red" }}
                />
              </>
            )}
            <input
              type="email"
              name="email"
              id="email"
              placeholder="email"
              className="text-input"
              value={email}
              onChange={handleEmailChange}
              required
              style={{ borderColor: isEmailValid ? "" : "red" }}
            />
            <div id="signupPassword">
              <div className="relative w-full h-full">
                <input
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Mot de passe"
                  className="text-input"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />

                <div className="absolute top-0 right-0 h-full w-12 flex items-center justify-center">
                  {passwordVisible ? (
                    <IoMdEyeOff
                      onClick={() => setPasswordVisible(!passwordVisible)}
                      className="w-full h-1/2 cursor-pointer"
                    />
                  ) : (
                    <IoIosEye
                      onClick={() => setPasswordVisible(!passwordVisible)}
                      className="w-full h-1/2 cursor-pointer"
                    />
                  )}
                </div>
              </div>
              {action === "signup" && (
                <div className="relative w-full h-full">
                  <input
                    type={confirmPasswordVisible ? "text" : "password"}
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="Confirmer le mot de passe"
                    className="text-input"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <div className="absolute top-0 right-0 h-full w-12 flex items-center justify-center">
                    {confirmPasswordVisible ? (
                      <IoMdEyeOff
                        onClick={() =>
                          setConfirmPasswordVisible(!confirmPasswordVisible)
                        }
                        className="w-full h-1/2 cursor-pointer"
                      />
                    ) : (
                      <IoIosEye
                        onClick={() =>
                          setConfirmPasswordVisible(!confirmPasswordVisible)
                        }
                        className="w-full h-1/2 cursor-pointer"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
            {action === "signup" && (
              <div>
                <div className="text-xs">
                  <label>
                    <input
                      type="checkbox"
                      id="termsCheckbox"
                      checked={acceptTerms}
                      onChange={(e) => setAcceptTerms(e.target.checked)}
                      style={{ marginRight: "5px" }}
                    />
                    En cochant cette case, je consens au traitement de mes
                    données personnelles. Je dispose d'un droit d'accès, de
                    rectification et d'opposition aux données me concernant en
                    contactant Legaware. Pour plus d'informations, consultez
                    notre <span onClick={() => setCgus("Politique de confidentialité")} className="text-blue-500 underline cursor-pointer">POLITIQUE DE CONFIDENTIALITÉ.</span>{" "}
                  </label>
                </div>
                <div className="text-xs">
                  <input
                    type="checkbox"
                    id="cgusCheckbox"
                    className="mr-2"
                    checked={acceptCgus}
                    onChange={(e) => setAcceptCgus(e.target.checked)}
                  />
                  <label htmlFor="cgusCheckbox">
                    En cochant cette case, je reconnais avoir pris connaissance
                    et accepté les{" "}
                    <span onClick={() => setCgus("CGU")} className="text-blue-500 underline cursor-pointer">
                      CONDITIONS GÉNÉRALES DE VENTES ET D'UTILISATION DU SITE
                    </span>
                    infogreffe.mesobligations.fr.
                  </label>
                </div>
              </div>
            )}

            <button
              className={"signupFormContainerContinuer"}
              type="submit"
              disabled={
                !isFirstNameValid || !isLastNameValid || !isEmailValid ||
                (action === "signup" && (!acceptTerms || !acceptCgus))
              }
            >
              Continuer
            </button>
          </form>
          {action === "login" ? (
            <button
              onClick={handleForgotPassword}
              className={`forgotPasswordButton text-first-oliver`}
            >
              Mot de passe oublié
            </button>
          ) : null}
        </>
      ) : (
        <>
          <div className="flex justify-center space-x-6 mb-2 text-white w-full items-center">
            <p className="w-full text-center">J'ai déjà un compte</p>
            <p className="w-full text-center" onClick={() => setAction("signup")}>je n'ai pas encore de compte</p>
          </div>
          <div className="loginButtonWrapper">
            <button className="w-full whitespace-nowrap" onClick={() => setAction("login")}>Se connecter</button>
            <button className="w-full" onClick={() => setAction("signup")}>S'inscrire</button>
          </div>
        </>
      )}
      <div className="mt-4">

        <button className={"signupWithGoggle"} onClick={handleSignInWithGoggle}>
          <i>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width={20}
              height={20}
            >
              <path
                fill="#fbbb00"
                d="M113.47 309.408 95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z"
              />
              <path
                fill="#518ef8"
                d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z"
              />
              <path
                fill="#28b446"
                d="m416.253 455.624.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z"
              />
              <path
                fill="#f14336"
                d="m419.404 58.936-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z"
              />
            </svg>
          </i>
          <p>Se connecter avec Google</p>
        </button>
      </div>

      {errorMessage.trim() !== " " && (
        <div className="error_message">{errorMessage}</div>
      )}
      {isPopupVisible && (
        <ConfirmationPopup
          isVisible={isPopupVisible}
          onClose={() => setIsPopupVisible(false)}
        />
      )}
    </div>
  );
};

export default LoginFormInfogreffe;
