import { useEffect, useRef } from "react";
import { FaUserTie } from "react-icons/fa6";

const Modal = ({
  title,
  titleIcon,
  children,
  modalOpen = false,
  setModalOpen,
  modalSize,
  back = false,
  prevStep,
  activeTab,
  setActiveTab
}) => {

  const modalRef = useRef(null);  // Création de la référence

  function disableScroll() {
    document.body.style.overflow = 'hidden';
  }

  function enableScroll() {
    document.body.style.overflow = 'auto';
  }

  useEffect(() => {
    if (modalOpen) {
      disableScroll();
      // Scroll vers l'élément référencé lorsque le modal est ouvert
      if (modalRef.current) {
        modalRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      enableScroll();
    }

    // Cleanup function
    return () => enableScroll();
  }, [modalOpen]);

  return (
    <div>
      <input
        value={modalOpen}
        type="checkbox"
        checked={modalOpen}
        onChange={() => setModalOpen(!modalOpen)}
        className="modal-toggle"
      />
      <div className="modal w-full z-20">
        <div
          className={`p-0 relative modal-box  ${modalSize == "large" && `${title === 'Mon compte' ? 'w-[95%]' : 'w-[80%]'} max-w-none h-full`
            } ${modalSize == "auto" && "lg:w-[45%] w-[90%] max-h-full max-w-none"
            } ${modalSize == "medium" && "lg:w-2/5 w-[90%] max-h-full max-w-none"
            }
          ${modalSize == "cgus" && "lg:w-3/5 w-[90%] max-h-[90%] max-w-none"
            }
           ${modalSize == "profile" && "lg:w-[65%] w-[90%] max-w-none"}`
          }
        >
          <label
            onClick={() => { console.log("modalOpen: ", modalOpen); setModalOpen(!modalOpen); console.log("modalOpen: AFTER: ", modalOpen); }}
            className="absolute btn btn-sm btn-circle right-2 top-2 text-[#06094F] bg-[#F1F1FF]"
          >
            ✕
          </label>
          {back &&
            <label
              onClick={() => prevStep()}
              className="absolute btn btn-sm btn-circle left-2 top-2 text-[#06094F] bg-[#F1F1FF]"
            >
              {"<"}
            </label>
          }
          {title && title === "Mon compte" ? (
            <div
              ref={modalRef}
              className="flex justify-center space-x-4 mt-4"> {/* Centre les boutons et ajoute un espace entre eux */}
              <button
                className={`px-6 py-2 rounded-lg ${activeTab === 'formalités' ? 'bg-first-oliver-color text-white' : 'bg-white text-first-oliver-color'} border border-first-oliver-color`}
                onClick={() => setActiveTab('formalités')}
              >
                Mes formalités
              </button>
              <button
                className={`px-6 py-2 rounded-lg ${activeTab === 'requêtes' ? 'bg-first-oliver-color text-white' : 'bg-white text-first-oliver-color'} border border-first-oliver-color`}
                onClick={() => setActiveTab('requêtes')}
              >
                Mes requêtes
              </button>

            </div>
          ) : (
            <div className="w-full flex justify-center bg-white py-4 items-center">

              {titleIcon && (titleIcon)}
              <h3 className="text-2xl font-bold">{title}</h3>
            </div>
          )}

          <div className={`${title === "Mon compte" ? "px-1" : "px-6"} py-2 `}>{children}</div>
        </div>
        <label
          className="modal-backdrop"
          onClick={() => setModalOpen(!modalOpen)}
        >
          Close
        </label>
      </div>
    </div>
  );
};

export default Modal;
