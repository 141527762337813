import SideBar from "../components/SideBar";
import ChatView from "../components/chat_view/ChatView";
import RightPanel from "../components/right_panel/RightPanel";
import { useState } from "react";
import { useAppContext } from "../context/AppContext";

const Home = () => {
  const [rightPanelOpen, setRightPanelOpen] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [buttonsOpen, setButtonsOpen] = useState(true);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [showPanel, setShowPanel] = useState(true);

  const {
    liensUtiles,
    experts,
    messages,
    writing,
    selectedOption,
    clearChat,


  } = useAppContext();

  return (
    <div className="flex transition duration-500 ease-in-out w-[100%] h-[100dvh]" onClick={() => { openMenuId && setOpenMenuId(null) }}>
      <div className="flex w-[100vw] md:w-[100%] relative bg-[#F6F9FD]">
        <SideBar
          sideBarOpen={sideBarOpen}
          setSideBarOpen={setSideBarOpen}
          buttonsOpen={buttonsOpen}
          setButtonsOpen={setButtonsOpen}
          openMenuId={openMenuId}
          setOpenMenuId={setOpenMenuId}
          showPanel={showPanel}
          setShowPanel={setShowPanel}
        />

        <div className="flex flex-col h-full duration-300 w-max overflow-hidden">
          <ChatView
            rightPanelOpen={rightPanelOpen}
            sideBarOpen={sideBarOpen}
            setSideBarOpen={setSideBarOpen}
            setRightPanelOpen={setRightPanelOpen}
            mobileMode={window.innerWidth < 768}
            showPanel={showPanel}
            setShowPanel={setShowPanel}
          />
          {window.innerWidth < 768 && (

            <RightPanel
              mobileMode={true}
              rightPanelOpen={rightPanelOpen}
              setRightPanelOpen={setRightPanelOpen}
              sideBarOpen={sideBarOpen}
              setSideBarOpen={setSideBarOpen}
            />
          )}
        </div>
        {window.innerWidth >= 768 && (liensUtiles.length > 0 || experts.length > 0 || messages[messages.length - 1]?.id === -4) && !writing && showPanel && (
          <RightPanel mobileMode={false}
            rightPanelOpen={rightPanelOpen}
            setRightPanelOpen={setRightPanelOpen}
            setSideBarOpen={setSideBarOpen}
            sideBarOpen={sideBarOpen}
            showPanel={showPanel}
            setShowPanel={setShowPanel}
          />
        )}
      </div>
    </div>
  );
};

export default Home;
