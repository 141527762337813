import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IoTriangle, IoCaretDown } from "react-icons/io5";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { BsDownload } from "react-icons/bs";
import FileUploadComponent from './FileUploadComponent';
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { useAppContext } from "../../context/AppContext";
import { ref, deleteObject, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from '../../firebase.config';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import useWindowSize from '../../hooks/useWindowSize';
import ReservationSection from '../chat_view/chat_message/ReservationSection';
import Modal from '../Modal';
import logoMesFormalitesClair from "../../assets/MesFormalites.Legal4.png";
import logoInfogreffe from "../../assets/logoOliver_vanillaDark3-removebg-preview.png";

import ValidationDevis from './ValidationDevis';
import { fetchData } from '../../utils/ask_api';

const FormalitiesTable = ({ formalities, setFormalities, setModalProfileOpen, formalitieJuridique }) => {
    const { currentUser, clearChat, subdomain, subDomains } = useAppContext();
    const [expandedRows, setExpandedRows] = React.useState({});

    const { width } = useWindowSize();
    // Définir un seuil pour distinguer PC et smartphone
    const isMobile = width < 768;
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [modalReservationOpen, setModalReservationOpen] = useState(false);
    const [selectedFormality, setSelectedFormality] = useState(null);
    const [isModalOpenNewCompany, setModalOpenNewCompany] = useState(false);
    const [companyNew, setCompanyNew] = useState("");
    const [statusMessage, setStatusMessage] = useState("");


    useEffect(() => {
        if (modalReservationOpen) {
            // Scroll vers le haut de la page ou vers un élément spécifique
            window.scrollTo(0, 0);  // Scrolle vers le haut de la page
            // Vous pouvez aussi cibler un élément spécifique si le modal n'est pas en haut de la page
            // document.getElementById('idDeVotreModal').scrollIntoView();
        }
    }, [modalReservationOpen]);

    const toggleExpand = (id) => {
        setExpandedRows((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };



    const getStatusClass = (status) => {
        switch (status) {
            case "En attente du devis":
                return 'bg-yellow-200 text-yellow-600';
            case 'En attente de l’import des pièces et documents':
                return 'bg-[#F311FF] text-white';
            case "Traitement en cours":
                return 'bg-yellow-200 text-yellow-600';
            case "Transmis au greffe":
                return 'text-gray-500';
            case "Validé par le greffe":
                return 'bg-yellow-200 text-yellow-600';
            case "Rejeté par le greffe":
                return 'bg-red-200 text-red-600'
            case "Dossiers refusés":
                return 'bg-red-200 text-red-600'
            case "Formalités clôtures":
                return 'bg-green-400 text-white'
            case "eDevis validés":
                return 'text-gray-500';
            default:
                return 'text-gray-500';
        }
    };


    const getStatus = (status) => {
        switch (status) {
            case "Formalités clôtures":
                return 'Formalité clôturée'
            case "eDevis validés":
                return 'En attente de paiement';
            default:
                return status;
        }
    };

    const [sortCriteria, setSortCriteria] = useState('dateReservation');
    const [sortDirection, setSortDirection] = useState('desc');

    const sortFormalities = (formalities) => {
        return formalities.sort((a, b) => {
            if (sortCriteria === 'dateReservation') {
                const dateA = new Date(a.dateReservation.seconds * 1000);
                const dateB = new Date(b.dateReservation.seconds * 1000);
                return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
            } else if (sortCriteria === 'status') {
                return sortDirection === 'asc' ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
            }
            return 0;
        });
    };

    const handleSort = (criteria) => {
        if (sortCriteria === criteria) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortCriteria(criteria);
            setSortDirection('asc');
        }
    };


    const handleFileUploadSuccess = async (formalityId, docId, fileUrl, fileName, uploadDate) => {
        setFormalities(currentFormalities => {
            return currentFormalities.map(formality => {
                if (formality.id === formalityId) {
                    const updatedDocuments = formality.required_documents.map(doc => {
                        if (doc.id === docId) {
                            return { ...doc, fileUrl: fileUrl, uploaded: true, fileName: fileName, uploadDate: uploadDate };
                        }
                        return doc;
                    });
                    return { ...formality, required_documents: updatedDocuments };
                }
                return formality;
            });
        });

        // Mettez à jour Firestore ici pour incrémenter le compteur et stocker l'URL du fichier
        const userFormalitiesRef = doc(db, `formalities/${currentUser.uid}`);
        try {
            const docSnap = await getDoc(userFormalitiesRef);

            if (docSnap.exists()) {
                let userData = docSnap.data();

                let updatedFormality = userData[formalityId];
                let updatedDocuments = updatedFormality.required_documents.map((document, index) => {
                    if (index === docId) {
                        return { ...document, fileUrl: fileUrl, uploaded: true, fileName: fileName, uploadDate: uploadDate };
                    }
                    return document;
                });

                updatedFormality.required_documents = updatedDocuments;

                // Mise à jour de Firestore avec la formalité mise à jour
                await updateDoc(userFormalitiesRef, {
                    [formalityId]: updatedFormality
                });

                console.log("Document mis à jour avec succès");
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour du document:", error);
        }
    };

    const handleFileUploadRemove = async (formalityId, docId) => {
        const userFormalitiesRef = doc(db, `formalities/${currentUser.uid}`);
        try {
            const docSnap = await getDoc(userFormalitiesRef);

            if (docSnap.exists()) {
                let userData = docSnap.data();
                let updatedFormality = userData[formalityId];
                let fileUrlToRemove = "";

                let updatedDocuments = updatedFormality.required_documents.map((document, index) => {
                    if (index === docId) {
                        fileUrlToRemove = document.fileUrl;
                        return { ...document, fileUrl: null, uploaded: false, fileName: "", uploadDate: null };
                    }
                    return document;
                });

                updatedFormality.required_documents = updatedDocuments;

                await updateDoc(userFormalitiesRef, {
                    [formalityId]: updatedFormality
                });

                if (fileUrlToRemove) {
                    const filePath = new URL(fileUrlToRemove).pathname.split('/').slice(3).join('/');
                    const fileRef = ref(storage, filePath);
                    try {
                        await deleteObject(fileRef);
                        console.log("Fichier supprimé avec succès du stockage Firebase");
                    } catch (error) {
                        console.error("Erreur lors de la suppression du fichier:", error);
                    }
                }
                console.log("Firestore mis à jour avec succès après suppression du fichier");

                setFormalities(currentFormalities => {
                    return currentFormalities.map(formality => {
                        if (formality.id === formalityId) {
                            return { ...formality, required_documents: updatedDocuments };
                        }
                        return formality;
                    });
                });

            }
        } catch (error) {
            console.error("Erreur lors de la suppression du fichier ou de la mise à jour de Firestore:", error);
        }
    };

    const isAllDocumentsRequisUploaded = (formality) => {
        return formality.required_documents.every(doc => {
            return !doc.required || (doc.required && doc.fileUrl);
        });

    };

    // Fonction appelée lorsque l'utilisateur clique sur "Envoyer"
    const handleSendFormality = async (formalityId) => {
        // Référence au document de l'utilisateur dans Firestore
        const userFormalitiesRef = doc(db, `formalities/${currentUser.uid}`);
        try {
            // Récupération du document de l'utilisateur
            const docSnap = await getDoc(userFormalitiesRef);

            if (docSnap.exists()) {
                // Accès aux données de l'utilisateur
                let userData = docSnap.data();

                // Accès à la formalité spécifique à mettre à jour
                let updatedFormality = userData[formalityId];



                // Mise à jour du statut et de la date d'envoi de la formalité
                updatedFormality.status = "Traitement en cours";
                updatedFormality.sendDate = new Date().toISOString(); // Utiliser Timestamp de Firestore si nécessaire

                // Mise à jour de Firestore avec la formalité mise à jour
                await updateDoc(userFormalitiesRef, {
                    [formalityId]: updatedFormality
                });

                // Convertir les timestamps en format ISO
                if (updatedFormality.dateReservation) {
                    updatedFormality.dateReservation = new Date(updatedFormality.dateReservation.seconds * 1000).toISOString();
                }
                if (updatedFormality.required_documents) {
                    updatedFormality.required_documents = updatedFormality.required_documents.map(doc => {
                        if (doc.uploadDate) {
                            doc.uploadDate = new Date(doc.uploadDate.seconds * 1000).toISOString();
                        }
                        return doc;
                    });
                }

                console.log("Formalité envoyée avec succès", updatedFormality);
                fetchData('formality-send', updatedFormality, 'POST')
                    .then(data => {
                        console.log('Success:', data);
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            }
        } catch (error) {
            console.error("Erreur lors de l'envoi de la formalité:", error);
        }
    };


    const displayFormattedDate = (timestamp) => {
        if (timestamp) {
            try {
                const date = timestamp.toDate();
                return `Envoyé le ${date.toLocaleDateString('fr-FR')}`;
            } catch (error) {
                const date = new Date(timestamp);
                return `Envoyé le ${date.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' })}`;
            }
        }
        return 'Date non disponible';
    };

    // Initialiser un état pour stocker les titres de tous les documents
    const [documentTitles, setDocumentTitles] = useState({});

    const handleTitleChange = (id, value) => {
        // Mettre à jour le titre spécifique pour la formalité par son ID
        setDocumentTitles(prev => ({ ...prev, [id]: value }));
    };

    const isValidFile = (file) => {
        // La taille maximale autorisée en octets (20Mo = 20 * 1024 * 1024 octets)
        const maxSize = 20 * 1024 * 1024;
        const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

        return file.size <= maxSize && allowedTypes.includes(file.type);
    };

    const handleFileChange = (e, formalityId, docIndex) => {
        const file = e.target.files[0];
        if (!file) return;
        if (!isValidFile(file)) {
            alert("Fichier invalide. Assurez-vous que la taille ne dépasse pas 20Mo et que le type de fichier est autorisé.");
            return;
        }
        uploadFile(file, formalityId, docIndex);
    };

    const uploadFile = (file, formalityId, docIndex) => {

        console.log(`formalities/${formalityId}/documents/${docIndex}/${file.name}`);
        const storageRef = ref(storage, `formalities/${formalityId}/documents/${docIndex}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                console.log(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    const date = new Date()
                    // setFileUrl(downloadURL);
                    // setUploading(false);
                    // onUploadSuccess(downloadURL, file.name, date);
                    console.log("formalityId: ", formalityId);
                    console.log("docIndex: ", docIndex);
                    console.log("downloadURL: ", downloadURL);
                    console.log("file.name: ", file.name);
                    console.log("date: ", date);
                    handleFileUploadSuccess(formalityId, docIndex, downloadURL, file.name, date)

                });
            }
        );
    };

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }


    const scrollableTableRef = useRef(null);
    // const countOccurrences = (formalities) => {
    //     const counts = {};

    //     formalities.forEach((formality) => {
    //         // Vérifie si l'entreprise existe déjà dans counts
    //         if (counts[formality.nomEntreprise]) {
    //             // Incrémente le compteur si l'entreprise existe déjà
    //             counts[formality.nomEntreprise].count += 1;
    //         } else {
    //             // Sinon, initialise le compteur et stocke le SIRET
    //             counts[formality.nomEntreprise] = { count: 1, siret: formality.siret };
    //         }
    //     });

    //     // Créer un tableau des entrées de l'objet et le transformer
    //     // pour inclure le nom de l'entreprise, le compteur et le SIRET
    //     return Object.entries(counts).map(([companyName, data]) => [companyName, data.count, data.siret]);
    // };

    const countOccurrences = (formalities) => {
        const counts = {};

        formalities.forEach((formality) => {
            const key = formality.siret
                ? `${formality.nomEntreprise}_${formality.siret}`
                : formality.nomEntreprise;

            if (counts[key]) {
                counts[key].count += 1;
            } else {
                counts[key] = {
                    count: 1,
                    nomEntreprise: formality.nomEntreprise,
                    siret: formality.siret,
                };
            }
        });

        return Object.values(counts);
    };

    const getCompanyKey = (company) => {
        return company.siret ? `${company.nomEntreprise}_${company.siret}` : company.nomEntreprise;
    };



    // const companyCounts = useMemo(() => countOccurrences(formalities), [formalities]);
    // const [formaliteFilterByCompany, setFormaliteFilterByCompany] = useState([]);
    // const [selectedCompany, setSelectedCompany] = useState(companyCounts.length > 0 ? companyCounts[0][0] : '');

    // const filterFormalites = useCallback((company) => {
    //     const filteredFormalites = formalities.filter(f => f.nomEntreprise === company);
    //     setFormaliteFilterByCompany(filteredFormalites);
    //     setSelectedCompany(company);
    // }, [formalities]);

    // useEffect(() => {
    //     if (selectedCompany) {
    //         filterFormalites(selectedCompany);
    //     }
    // }, [filterFormalites, selectedCompany]);

    const companyCounts = useMemo(() => countOccurrences(formalities), [formalities]);

    const [formalitiesFilteredByCompany, setFormalitiesFilteredByCompany] = useState([]);
    const [selectedCompanyKey, setSelectedCompanyKey] = useState(
        companyCounts.length > 0 ? getCompanyKey(companyCounts[0]) : ''
    );

    const filterFormalities = useCallback(
        (company) => {
            const key = getCompanyKey(company);
            const filteredFormalities = formalities.filter((f) => {
                const formalityKey = f.siret
                    ? `${f.nomEntreprise}_${f.siret}`
                    : f.nomEntreprise;
                return formalityKey === key;
            });
            setFormalitiesFilteredByCompany(filteredFormalities);
            setSelectedCompanyKey(key);
        },
        [formalities]
    );

    useEffect(() => {
        if (selectedCompanyKey) {
            const selectedCompany = companyCounts.find(
                (company) => getCompanyKey(company) === selectedCompanyKey
            );
            if (selectedCompany) {
                filterFormalities(selectedCompany);
            }
        }
    }, [filterFormalities, selectedCompanyKey, companyCounts]);


    useEffect(() => {
        if (formalities.length > 0 && scrollableTableRef.current) {
            scrollableTableRef.current.scrollLeft = scrollableTableRef.current.scrollWidth;
        }
    }, [formalities]);

    const getSiret = (company) => {
        const companyFiltered = formalities.filter(f => f.nomEntreprise === company);
        return companyFiltered.length > 0 ? companyFiltered[0].siret : '';
    }



    const myRef = useRef(null);

    const scrollToRef = () => {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
    }


    const [shouldScroll, setShouldScroll] = useState(false);

    useEffect(() => {
        if (shouldScroll && myRef.current) {
            myRef.current.scrollIntoView({ behavior: 'smooth' });
            setShouldScroll(false); // Réinitialiser le flag
        }
    }, [shouldScroll, myRef]);

    function formatSIRET(siret) {
        return siret.replace(/(\d{3})(?=\d)/g, "$1 ");
    }



    const countUploadedDocuments = (documents) => {
        return documents.filter(doc => doc.uploaded && doc.fileUrl).length;
    };

    const sortedCompanyCounts = useMemo(() => {
        // Créer une copie du tableau pour éviter de le modifier directement
        return [...companyCounts].sort((a, b) =>
            a.nomEntreprise.localeCompare(b.nomEntreprise)
        );
    }, [companyCounts]);


    // if (isMobile) {
    //     // Rendu pour smartphones
    //     return (
    //         <div className="mt-6">
    //             {formalities.map((formality, index) => (
    //                 <div key={`formality-${index}`} className="mb-4 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4">
    //                     <div className="grid grid-cols-2 gap-4 mb-4">
    //                         <div className="col-span-2 md:col-span-1 font-bold">Date de demande:</div>
    //                         <div className="col-span-2 md:col-span-1">{new Date(formality.dateReservation.seconds * 1000).toLocaleDateString()}</div>
    //                         <div className="col-span-2 font-bold">Formalité:</div>
    //                         <div className="col-span-2">{formality.categorie} {">"} {formality.titreFormalitie}</div>
    //                         <div className="col-span-2 font-bold">Dénomination:</div>
    //                         <div className="col-span-2">{formality.nomEntreprise}</div>
    //                         <div className="col-span-2 font-bold">Montant HT:</div>
    //                         <div className="col-span-2">{formality.montantHT} €</div>
    //                         <div className="col-span-2 font-bold">Contact :</div>
    //                         <div className="col-span-2">
    //                             {formality.prenom} {formality.nom}<br />
    //                             {formality.email_contact}
    //                         </div>

    //                         <div className="col-span-2 font-bold">Facture :</div>
    //                         <div className="col-span-2">
    //                             {formality.invoice_pdf_url && (
    //                                 <a href={formality.invoice_pdf_url} className="text-blue-600 hover:text-blue-800 hover:underline" target="_blank" rel="noopener noreferrer">Facture </a>
    //                             )}
    //                         </div>

    //                         <div className="col-span-2 font-bold">Statut :</div>
    //                         <div className={`col-span-2 ${getStatusClass(formality.status)}`}>
    //                             {formality.status}
    //                             {formality?.urlRecepisse &&
    //                                 <a href={formality.urlRecepisse} className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Télécharger le récépissé</a>

    //                             }
    //                         </div>

    //                         {formality.sendDate && (
    //                             <span className="text-[#06094F]"> {displayFormattedDate(formality.sendDate)}</span>
    //                         )}

    //                         <button onClick={() => toggleExpand(formality.id)} className="col-span-2 flex justify-between items-center text-[#06094F]">
    //                             <span>Documents & pièces requis ({formality?.upload_number_file || 0} / {formality.required_documents.length})</span>
    //                             {expandedRows[formality.id] ? <IoIosArrowUp /> : <IoIosArrowDown />}
    //                         </button>

    //                         {/* Contenu dépliable */}
    //                         {expandedRows[formality.id] && (
    //                             <div className="col-span-2">
    //                                 <div className="mt-4">
    //                                     {formality.required_documents.map((doc, docIndex) => (
    //                                         <div key={docIndex} className="mb-8">
    //                                             <div className="mb-2 flex flex-col items-start">
    //                                                 <label htmlFor={`file-input-${docIndex}`} className="block text-sm font-bold text-[#06094F] dark:text-white mb-1">
    //                                                     {doc.titrePiece}
    //                                                     {doc.required && <span className="text-red-500">*</span>}
    //                                                     {doc.urlModel && (
    //                                                         <a href={doc.urlModel} className="inline-flex items-center ml-2 text-blue-600 hover:text-blue-800 hover:underline">
    //                                                             <BsDownload className='w-5 h-5 mr-2' />
    //                                                             Télécharger modèle
    //                                                         </a>
    //                                                     )}
    //                                                 </label>
    //                                             </div>
    //                                             <FileUploadComponent
    //                                                 docIndex={docIndex + '-' + doc.titrePiece}
    //                                                 formalityId={formality.id}
    //                                                 existingFileUrl={doc.fileUrl}
    //                                                 existingFileName={doc.fileName}
    //                                                 existingUploadDate={doc.uploadDate}
    //                                                 isSent={!!formality.sendDate}
    //                                                 onUploadSuccess={(downloadURL, fileName, uploadDate) => {
    //                                                     console.log(`Fichier pour le document ${downloadURL} a été téléchargé avec succès}`);
    //                                                     handleFileUploadSuccess(formality.id, docIndex, downloadURL, fileName, uploadDate)
    //                                                 }}
    //                                                 onUploadRemove={() => {
    //                                                     console.log(`Fichier a été supprimé avec succès`);
    //                                                     handleFileUploadRemove(formality.id, docIndex)
    //                                                 }}
    //                                                 pathStorage={"documents"}
    //                                             />
    //                                         </div>
    //                                     ))}
    //                                 </div>
    //                                 <div className="flex justify-center space-x-2">
    //                                     {!formality.sendDate && (
    //                                         <>
    //                                             <button
    //                                                 className={`p-2 rounded-lg px-8 bg-transparent text-[#06094F] border-2 ${isAllDocumentsRequisUploaded(formality) ? 'border-blue-logo-color hover:bg-blue-logo-color hover:text-white' : 'border-gray-300 text-gray-300 cursor-not-allowed'}`}
    //                                                 disabled={!isAllDocumentsRequisUploaded(formality)}
    //                                                 onClick={() => handleSendFormality(formality.id)}
    //                                             >
    //                                                 Envoyer
    //                                             </button>
    //                                         </>
    //                                     )}
    //                                 </div>
    //                             </div>
    //                         )}
    //                     </div>
    //                 </div>
    //             ))}
    //         </div>
    //     );
    // } else {
    // Rendu pour PC
    return (
        <div className="flex transition duration-500 ease-in-out w-full overflow-hidden">
            {!isMobile && (
                <div className="min-w-60 max-h-[30rem] bg-[#8EBAEA] border-l mr-2 border-[#EDF5FE] overflow-auto rounded-md">
                    <div className="flex flex-col">
                        <div className="text-[#06094F] text-center">
                            <h2 className="p-4">Mes entreprises</h2>
                        </div>
                        <button
                            className="mb-4 bg-[#7FF9D8] hover:bg-[#54E1BB] text-first-oliver-color font-bold py-2 px-4"
                            onClick={() => setModalOpenNewCompany(true)}
                        >
                            + Nouvelle entreprise
                        </button>
                        {sortedCompanyCounts.map((company) => (
                            <div
                                key={getCompanyKey(company)}
                                className={`py-2 px-4 flex rounded justify-between hover:bg-[#d0e0f3] cursor-pointer ${selectedCompanyKey === getCompanyKey(company)
                                    ? 'bg-[#b2d1f3]'
                                    : 'bg-[#8EBAEA]'
                                    }`}
                                onClick={() => filterFormalities(company)}
                            >
                                <button className="text-[#06094F] uppercase font-bold">
                                    <div className="text-left">
                                        <p>{company.nomEntreprise}</p>
                                        <p className="text-xs text-slate-100">
                                            {company.siret && formatSIRET(company.siret)}
                                        </p>
                                    </div>
                                </button>
                                <button className="text-[#06094F] rounded-sm w-5 h-5 bg-white">
                                    {company.count}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <div ref={scrollableTableRef} className="overflow-x-auto flex-1 px-1 mt-6">
                <div className="flex justify-end">
                    <button
                        onClick={() => {
                            clearChat();
                            setModalProfileOpen(false);
                            const selectedCompany = companyCounts.find(
                                (company) => getCompanyKey(company) === selectedCompanyKey
                            );
                            formalitieJuridique(selectedCompany.nomEntreprise, selectedCompany.siret);
                        }}
                        className="mb-2 bg-[#7FF9D8] hover:bg-[#54E1BB] text-right items-end rounded-md text-first-oliver-color font-bold py-1 px-4"
                    >
                        + Nouvelle formalité
                    </button>
                </div>
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-[#06094F] bg-[#8EBAEA] dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-3 py-3">
                                Date de demande
                                <button onClick={() => handleSort('dateReservation')}>🔽</button>
                            </th>
                            <th scope="col" className="px-3 py-3">Formalité</th>
                            <th scope="col" className="px-3 py-3">Dénomination</th>
                            <th scope="col" className="px-3 py-3">Montant TTC</th>
                            <th scope="col" className="px-3 py-3">Facture/eDevis</th>
                            <th scope="col" className="px-3 py-3">
                                Statut
                                <button onClick={() => handleSort('status')}>🔽</button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {sortFormalities(isMobile ? formalities : formalitiesFilteredByCompany).map((formality, index) => (
                                <React.Fragment key={`fragment-${formality.dateReservation}-${index}`}>
                                    <tr>
                                        <div className='h-2'></div>
                                    </tr>
                                    <tr className="bg-[#EDF6FE] text-[#06094F]">
                                        <td className="px-3 py-2 first-cell text-left padded-cell">
                                            {new Date(formality.dateReservation.seconds * 1000).toLocaleDateString()}
                                        </td>
                                        <td className="px-3 py-2 middle-cell text-left padded-cell">
                                            {Capitalize(formality.categorie)} {">"} {Capitalize(formality.titreFormalitie)}
                                        </td>
                                        <td className="px-3 py-2 middle-cell text-left padded-cell">
                                            {formality.nomEntreprise}
                                        </td>
                                        <td className="px-3 py-2 middle-cell text-left padded-cell">
                                            {formality.status === "eDevis validés" ? (
                                                <span>{formality?.eDevis.amount_devis.toFixed(2)} €</span>
                                            ) : (
                                                formality.status === "En attente du devis" ? (
                                                    <span> </span>
                                                ) : (
                                                    <span>{formality?.montantTTC?.toFixed(2) || formality?.montantHT.toFixed(2)} €</span>
                                                )
                                            )}
                                        </td>

                                        <td className="px-3 py-2 middle-cell text-left padded-cell">
                                            {formality.invoice_pdf_url && (
                                                <a href={formality.invoice_pdf_url} className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Facture</a>
                                            )}
                                            {formality.status === "eDevis validés" && (
                                                <button
                                                    onClick={() => {
                                                        // window.scrollTo({
                                                        //     top: document.body.scrollHeight,
                                                        //     behavior: 'smooth'  // Défilement doux
                                                        // });
                                                        // scrollToRef()
                                                        // clearChat()
                                                        if (isMobile) setShouldScroll(true);
                                                        setSelectedFormality(formality);
                                                        setModalReservationOpen(!modalReservationOpen);
                                                        setShowPaymentForm(false);

                                                    }}
                                                    className='bg-[#7FF9D8] hover:bg-[#54E1BB] text-first-oliver-color py-2 px-2 rounded-md'> Afficher le devis</button>
                                            )}
                                        </td>
                                        <td className={`px-3 py-2 last-cell padded-cell`}>
                                            <p className={` border font-bold rounded-md p-1 text-center ${getStatusClass(formality.status)}`}> {getStatus(formality.status)} </p>
                                            {formality.status === "Formalités clôtures" && (
                                                <>
                                                    <p>Télécharger :</p>
                                                    {formality.validation_documents.map((doc, docIndex) => (
                                                        doc.fileUrl && (
                                                            <p key={docIndex}>
                                                                <a href={doc.fileUrl} className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">
                                                                    - {doc.titrePiece}
                                                                </a>
                                                            </p>
                                                        )
                                                    ))}
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <td colSpan={8}>
                                            <div className='h-[0.125rem] w-full  first-cell-tr'></div>

                                        </td>
                                    </tr>
                                    <tr onClick={() => toggleExpand(formality.id)} className="bg-[#EDF5FE] cursor-pointer border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-[#b1d3f9b2]">
                                        <td className={`px-6 text-center ${!expandedRows[formality.id] ? 'bottom-cell-tr rounded-bl-md ' : 'left-cell-tr'}`} colSpan="5">
                                            <div className='text-[#06094F]'>
                                                Documents & pièces requises : ({countUploadedDocuments(formality.required_documents)} / {formality.required_documents.length})
                                            </div>
                                        </td>
                                        <td className={`px-6 text-right  ${!expandedRows[formality.id] ? 'bottom-right-cell-tr rounded-br-md' : 'right-cell-tr'} `}>
                                            <div className="flex items-end justify-end space-x-1">
                                                <div className="flex items-center justify-center space-x-1">
                                                    {formality.sendDate ? (
                                                        <span className="text-[#06094F]"> {displayFormattedDate(formality.sendDate)}</span>
                                                    ) : (
                                                        <>
                                                            {console.log("Envoyer condition", !["En attente du devis", "eDevis validés"].includes(formality.status), formality.status)}
                                                            {!["En attente du devis", "eDevis validés"].includes(formality.status) &&
                                                                <button
                                                                    className={`p-2 rounded-lg px-8 bg-transparent text-[#06094F] border-2 ${isAllDocumentsRequisUploaded(formality) ? 'border-blue-logo-color hover:bg-blue-logo-color hover:text-white' : 'border-gray-300 text-gray-300 cursor-not-allowed'} ${!isAllDocumentsRequisUploaded(formality) && "hidden"}`}
                                                                    disabled={!isAllDocumentsRequisUploaded(formality)}
                                                                    onClick={() => {
                                                                        if (formality.status === "eDevis validés") {
                                                                            alert("Veuillez payer le eDevis pour l'envoi de vos documents")
                                                                            clearChat()
                                                                            setSelectedFormality(formality);
                                                                            setModalReservationOpen(!modalReservationOpen);
                                                                            setShowPaymentForm(false);
                                                                        } else {
                                                                            handleSendFormality(formality.id)
                                                                        }
                                                                    }}
                                                                >
                                                                    Envoyer
                                                                </button>
                                                            }
                                                        </>
                                                    )}
                                                    <button
                                                        className="flex items-end justify-end"
                                                    >
                                                        {expandedRows[formality.id] ? <FaChevronUp className='text-[#06094F]' /> : <FaChevronDown className='text-[#06094F]' />}
                                                    </button>
                                                </div>

                                            </div>
                                        </td>

                                    </tr>
                                    {
                                        expandedRows[formality.id] && (
                                            <tr className='border-b'>
                                                <td colSpan="6" className={` ${expandedRows[formality.id] ? 'bottom-cell-tr bottom-right-cell-tr rounded-br-md rounded-bl-md' : 'rounded-md'}`} >
                                                    <div className='mb-1 bg-[#EDF5FE]'>
                                                        <div className='py-4 w-full max-w-4xl mx-auto'>
                                                            {formality.required_documents.map((doc, docIndex) => (
                                                                <div key={docIndex} className="mb-8">
                                                                    <div className="mb-2 flex flex-col items-start">
                                                                        <label htmlFor={`file-input-${docIndex}`} className="block text-sm font-bold text-[#06094F] dark:text-white mb-1">
                                                                            {doc.titrePiece}
                                                                            {doc.required && <span className="text-red-500">*</span>}
                                                                            {doc.urlModel && (
                                                                                <a href={doc.urlModel}
                                                                                    className="inline-flex items-center ml-2 text-blue-600 hover:text-blue-800 hover:underline"
                                                                                    rel="noopener noreferrer"
                                                                                    target="_blank"
                                                                                >
                                                                                    <BsDownload className='w-5 h-5 mr-2' />
                                                                                    Télécharger modèle
                                                                                </a>
                                                                            )}
                                                                        </label>
                                                                    </div>
                                                                    <FileUploadComponent
                                                                        docIndex={docIndex + '-' + doc.titrePiece}
                                                                        formalityId={formality.id}
                                                                        existingFileUrl={doc.fileUrl}
                                                                        existingFileName={doc.fileName}
                                                                        existingUploadDate={doc.uploadDate}
                                                                        registerFormalaties={false}
                                                                        clientSendAllDocs={formality?.sendDate}
                                                                        isSent={formality.sendDate && formality.status === "Formalités clôtures"}
                                                                        onUploadSuccess={(downloadURL, fileName, uploadDate) => {
                                                                            console.log(`Fichier pour le document ${downloadURL} a été téléchargé avec succès}`);
                                                                            handleFileUploadSuccess(formality.id, docIndex, downloadURL, fileName, uploadDate)
                                                                        }}
                                                                        onUploadRemove={() => {
                                                                            console.log(`Fichier a été supprimé avec succès`);
                                                                            handleFileUploadRemove(formality.id, docIndex)
                                                                        }}
                                                                        pathStorage={"documents"}
                                                                    />
                                                                </div>
                                                            ))}

                                                            {/* <label className="block text-sm font-bold text-[#06094F] dark:text-white mb-1">
                                                                    Nouveau document
                                                                </label>
                                                                <div key={formality.id} className='flex w-full items-center'>
                                                                    <input
                                                                        type='text'
                                                                        style={{ flex: '3' }}
                                                                        value={documentTitles[formality.id] || ''} // Utiliser l'ID de la formalité pour différencier les états
                                                                        onChange={(e) => handleTitleChange(formality.id, e.target.value)}
                                                                        placeholder='Titre du document...'
                                                                        className='bg-white border-slate-400 border-2 rounded-lg p-3 text-black flex-grow mr-2'
                                                                        id={`inputTitreDocument-${formality.id}`}
                                                                    />
                                                                    <label
                                                                        className="bg-blue-500 text-white rounded-lg shadow-lg cursor-pointer text-center hover:bg-blue-400 flex-shrink-0 p-3"
                                                                        style={{ flex: '1' }}>
                                                                        <span className="text-base leading-normal">Chercher un fichier</span>
                                                                        <input
                                                                            type="file"
                                                                            className="hidden"
                                                                            disabled={!documentTitles[formality.id]}
                                                                            onChange={(e) => {
                                                                                const titrePiece = documentTitles[formality.id];
                                                                                handleFileChange(e, formality.id, `${formality.required_documents.length}-${titrePiece}`);
                                                                            }}
                                                                        />
                                                                    </label>
                                                                </div> */}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </React.Fragment>
                            ))}
                        </>
                    </tbody>
                </table>
                {modalReservationOpen &&
                    <ValidationDevis
                        showPaymentForm={showPaymentForm}
                        setShowPaymentForm={setShowPaymentForm}
                        modalReservationOpen={modalReservationOpen}
                        setModalReservationOpen={setModalReservationOpen}
                        eDevisIsSand={true}
                        selectedFormality={selectedFormality}
                        myRef={myRef}
                        isMobile={isMobile}
                        setModalProfileOpen={setModalProfileOpen}
                    />
                }

                <Modal
                    title=""
                    modalOpen={isModalOpenNewCompany}
                    setModalOpen={setModalOpenNewCompany}
                    modalSize={"auto"}
                >
                    <div className="flex flex-col items-center justify-center p-4">
                        <img src={`${subDomains.includes(subdomain) ? logoMesFormalitesClair : logoInfogreffe}`} alt="Logo" className="w-40 mb-6" />

                        <p className="text-center"> Veuillez renseigner le nom de votre nouvelle entreprise </p>
                        <input className='input p-3 my-3 mb-6bg-[#F4F4F4] border-black input-bordered  w-80 '
                            value={companyNew}
                            onChange={(e) => setCompanyNew(e.target.value)}
                            placeholder="Nom de l'entreprise">
                        </input>

                        {statusMessage && (
                            <div
                                className={`pl-2 text-sm text-red-500`}
                            >
                                {statusMessage}
                            </div>
                        )}

                        <button
                            onClick={() => {
                                if (companyNew.length > 0) {
                                    setModalProfileOpen(false);
                                    setModalOpenNewCompany(false);
                                    formalitieJuridique(companyNew, '')
                                } else {
                                    setStatusMessage("Veuillez renseigner le nom de votre nouvelle entreprise.")
                                }
                            }}
                            className="signupFormContainerContinuer text-white font-bold"
                        >
                            Valider
                        </button>
                    </div>
                </Modal>
            </div >
        </div>
    );
    // }
};

export default FormalitiesTable;
